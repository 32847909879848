<template>
  <div>
    <v-overlay :value="$apollo.queries.donteReport.loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>

    <div v-if="!$apollo.queries.donteReport.loading">
      <div v-if="!reportId || reportError">
        The requested report does not exist.
      </div>

      <div v-if="reportId && !reportError">
        <v-card class="mb-5 px-6 py-4">
          <v-card-title class="d-flex justify-space-between px-0 py-0">
            <div>
              <v-icon class="mr-4">
                {{ icons.mdiFileChartOutline }}
              </v-icon>
              Report ID: {{ donteReport.id }}
            </div>

            <div class="d-flex">
              <v-progress-circular
                v-if="isReportGenerating"
                indeterminate
                :size="35"
                color="secondary"
                class="mr-2"
              ></v-progress-circular>

              <v-btn
                v-if="donteReport.pdf == null || donteReport.pdf.status !== 'completed'"
                color="primary"
                class="mb-3 pl-3 pr-4"
                :disabled="isReportGenerating || $apollo.queries.donteReport.loading"
                @click="exportToPdf"
              >
                <v-icon class="mr-2">
                  {{ icons.mdiFileDownloadOutline }}
                </v-icon>
                Export to PDF
              </v-btn>

              <v-btn
                v-else
                color="primary"
                class="mb-3 pl-3 pr-4"
                :disabled="reportDownloading || $apollo.queries.donteReport.loading"
                @click="downloadPdf"
              >
                <v-icon class="mr-2">
                  {{ icons.mdiFileDownloadOutline }}
                </v-icon>
                Download PDF
              </v-btn>
            </div>
          </v-card-title>

          <div class="pl-11 pb-2">
            <div class="d-flex">
              <div>
                Report period:
              </div>

              <div class="pl-2">
                {{ datetime_local(donteReport.input_parameters.from) }}&nbsp;-&nbsp;{{ datetime_local(donteReport.input_parameters.until) }}
              </div>
            </div>

            <div class="d-flex">
              <div>
                Report type:
              </div>

              <div class="pl-2 text-lowercase">
                {{ donteReport.input_parameters.reportType }}
              </div>
            </div>

            <div class="d-flex">
              Ages:
              <div class="pl-2 d-flex">
                <div
                  v-for="(ageGroup, index) in donteReport.input_parameters.ageGroups"
                  :key="ageGroup"
                  class="d-flex pr-2"
                >
                  {{ ageGroupsDict[ageGroup] }}
                  <div v-if="donteReport.input_parameters.ageGroups.length > 1 && index !== donteReport.input_parameters.ageGroups.length - 1">
                    ,
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex">
              Genders:
              <div
                v-for="gender in donteReport.input_parameters.genders"
                :key="gender"
                class="pl-2 text-lowercase"
              >
                {{ gender }}
              </div>
            </div>

            <div class="d-flex">
              Tariffs:

              <div
                v-for="tariff in donteReport.input_parameters.tariffs"
                :key="tariff"
                class="pl-2 text-lowercase"
              >
                {{ tariff }}
              </div>
            </div>
          </div>
        </v-card>

        <v-card>
          <DonteReportMap
            id="donteReportMap"
            :bounds="bounds"
            :geohash-cells="donteReport.input_parameters.geohashCellsBoundingBoxes"
            class="mb-5"
          />
        </v-card>

        <v-card>
          <v-tabs
            v-model="tab"
            grow
          >
            <v-tab>Number of users & stay time</v-tab>
            <v-tab>Breakdown by countries</v-tab>
          </v-tabs>

          <v-tabs-items
            v-model="tab"
            class="px-6 py-4"
          >
            <v-tab-item>
              <v-container id="dataTables">
                <v-row>
                  <v-col
                    v-for="reportTimeFrame in donteReport.report"
                    :key="reportTimeFrame.from"
                    cols="4"
                  >
                    <v-card>
                      <v-simple-table :id="reportTimeFrame.from">
                        <template #default>
                          <thead>
                            <tr>
                              <th>From</th>
                              <th>{{ reportTimeFrame.from }}</th>
                            </tr>
                            <tr>
                              <th>Until</th>
                              <th>{{ reportTimeFrame.until }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Number of people in the area</td>
                              <td>{{ reportTimeFrame.data.cohortSum | format_number }}</td>
                            </tr>
                            <tr>
                              <td>Medium stay time (h)</td>
                              <td v-if="reportTimeFrame.data.medStayTime">
                                {{ reportTimeFrame.data.medStayTime | format_number }}
                              </td>
                              <td v-else>
                                Data unavailable
                              </td>
                            </tr>
                            <tr>
                              <td>Minimum stay time (h)</td>
                              <td v-if="reportTimeFrame.data.minStayTime">
                                {{ reportTimeFrame.data.minStayTime | format_number }}
                              </td>
                              <td v-else>
                                Data unavailable
                              </td>
                            </tr>
                            <tr>
                              <td>Maximum stay time (h)</td>
                              <td v-if="reportTimeFrame.data.maxStayTime">
                                {{ reportTimeFrame.data.maxStayTime | format_number }}
                              </td>
                              <td v-else>
                                Data unavailable
                              </td>
                            </tr>
                            <tr>
                              <th>Stay time by thresholds</th>
                              <th></th>
                            </tr>
                            <tr>
                              <td>0 - 5 min</td>
                              <td>{{ reportTimeFrame.data.stayTimeByThresholds.zeroToFive | format_number }}</td>
                            </tr>
                            <tr>
                              <td>5 - 30 min</td>
                              <td>{{ reportTimeFrame.data.stayTimeByThresholds.fiveToThirty | format_number }}</td>
                            </tr>
                            <tr>
                              <td>30 - 60 min</td>
                              <td>{{ reportTimeFrame.data.stayTimeByThresholds.thirtyToSixty | format_number }}</td>
                            </tr>
                            <tr>
                              <td>1 - 2 h</td>
                              <td>{{ reportTimeFrame.data.stayTimeByThresholds.oneToTwo | format_number }}</td>
                            </tr>
                            <tr>
                              <td>2h +</td>
                              <td>{{ reportTimeFrame.data.stayTimeByThresholds.twoAndMore | format_number }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col
                    v-for="reportTimeFrame in donteReport.report"
                    :key="reportTimeFrame.from"
                    cols="4"
                  >
                    <v-card>
                      <v-simple-table>
                        <template #default>
                          <thead>
                            <tr>
                              <th>From</th>
                              <th>{{ reportTimeFrame.from }}</th>
                            </tr>
                            <tr>
                              <th>Until</th>
                              <th>{{ reportTimeFrame.until }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="country in reportTimeFrame.data.countriesSum"
                              :key="country.country"
                            >
                              <td>{{ countryData[country.country.toUpperCase()] ? countryData[country.country.toUpperCase()].name : 'Unknown' }}</td>
                              <td class="d-flex d-flex justify-sm-center align-sm-center">
                                {{ country.total | format_number }}
                              </td>
                            </tr>

                            <tr v-if="reportTimeFrame.data.countriesSum.length === 0">
                              <td></td>
                              <td>
                                Data unavailable
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import L from 'leaflet'

import { mdiFileChartOutline, mdiFileDownloadOutline } from '@mdi/js'
import allCountriesData from 'country-data'

import DonteReportMap from './DonteReportMap.vue'

export default {
  components: {
    DonteReportMap,
  },

  filters: {
    format_number(num) {
      return new Intl.NumberFormat('hr-HR').format(num)
    },
  },

  data() {
    return {
      reportId: parseInt(this.$route.params.id, 10) ?? null,
      reportError: false,

      reportGenerating: false,
      reportDownloading: false,
      tab: null,
      countryData: undefined,
      selectionBounds: undefined,

      icons: {
        mdiFileChartOutline,
        mdiFileDownloadOutline,
      },

      ageGroupsDict: {
        AG_ALL: 'all',
        AG_18_25: '18 - 25',
        AG_25_35: '25 - 35',
        AG_35_45: '35 - 45',
        AG_45_55: '45 - 55',
        AG_55_65: '55 - 65',
        AG_65_AND_UP: '65+',
      },
    }
  },

  computed: {
    bounds() {
      /* eslint no-underscore-dangle: 0 */
      return L.latLngBounds(
        this.donteReport.input_parameters.selectionBounds._southWest,
        this.donteReport.input_parameters.selectionBounds._northEast,
      )
    },

    isReportGenerating() {
      return this.reportGenerating === true || this.donteReport?.pdf?.status === 'generating'
    },
  },

  mounted() {
    this.countryData = allCountriesData.countries
  },

  apollo: {
    donteReport: {
      query: gql`
        query ($reportId: ID!) {
          donteReport(id: $reportId) {
            id
            user_id
            status
            created_at
            pdf {
                id
                status
            }
            input_parameters {
              from
              until
              reportType
              selectionBounds {
                _southWest {
                  lat
                  lng
                }
                _northEast {
                  lat
                  lng
                }
              }
              ageGroups
              genders
              tariffs
              countries
              geohashCellsBoundingBoxes {
                southWest {
                  lat
                  lng
                }
                northEast {
                  lat
                  lng
                }
              }
            }
            report {
              from
              until
              data {
                medStayTime
                minStayTime
                maxStayTime
                stayTimeByThresholds {
                  zeroToFive
                  fiveToThirty
                  thirtyToSixty
                  oneToTwo
                  twoAndMore
                }
                cohortSum
                countriesSum {
                  country
                  total
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          reportId: this.reportId,
        }
      },
      error() {
        this.reportError = true
      },
      errorPolicy: 'all',
    },

    donteReportPdfStatus: {
      query: gql`
        query ($reportId: ID!) {
            donteReportPdfStatus(
                report_id: $reportId
            ) {
                id
                status
                created_at
                updated_at
            }
        }
      `,
      variables() {
        return {
          reportId: this.reportId,
        }
      },
      skip() {
        return this.isReportGenerating === false
      },
      result(result) {
        if (result.data != null && result.data.donteReportPdfStatus != null && result.data.donteReportPdfStatus.status !== 'generating') {
          this.reportGenerating = false
          this.$apollo.queries.donteReport.refetch()
        }
      },
      pollInterval: 5000,
    },
  },

  methods: {
    formatTime(time) {
      const timeDate = new Date(time)

      return timeDate.toLocaleString('hr', { hour12: false })
    },

    datetime_local(dt) {
      return this.$moment.utc(dt).local().format('L LT')
    },

    exportToPdf() {
      if (!this.donteReport.id) {
        this.$toastr.e('Report ID not provided!')
      } else {
        this.reportGenerating = true

        this.$apollo.mutate({
          mutation: gql`
            mutation ($reportId: ID!) {
                generateDonteReportPdf(id: $reportId) {
                    id
                }
            }
          `,
          variables: {
            reportId: this.reportId,
          },
        })
      }
    },

    downloadPdf() {
      this.reportDownloading = true

      this.$http.get(`/donte/download-report/${this.reportId}`, {
        responseType: 'blob',
      }).then(response => {
        const objectUrl = window.URL.createObjectURL(response.data)

        const link = document.createElement('a')
        link.href = objectUrl
        link.setAttribute('download', `donte-report-${this.reportId}.pdf`)
        link.click()

        window.setTimeout(() => {
          window.URL.revokeObjectURL(objectUrl)
        }, 1000)
      }).finally(() => {
        this.reportDownloading = false
      })
    },
  },
}
</script>
