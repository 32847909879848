var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('l-map',{ref:"donteReportMap",staticClass:"map-styling",staticStyle:{"height":"500px","width":"100%"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"bounds":_vm.bounds,"options":{
    zoomControl: false,
    boxZoom: false,
    doubleClickZoom: false,
    scrollWheelZoom: false,
    touchZoom: false,
    dragging: false,
    preferCanvas: true,
  }}},[_c('l-tile-layer',{attrs:{"url":_vm.$tileService.url,"attribution":_vm.$tileService.attribution}}),_vm._l((_vm.geohashCells),function(geohashCell){return _c('l-rectangle',{key:geohashCell.geohash,attrs:{"bounds":[geohashCell.southWest, geohashCell.northEast],"l-style":_vm.rectangleStyle,"interactive":false}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }