<template>
  <l-map
    ref="donteReportMap"
    style="height: 500px; width: 100%"
    :zoom="zoom"
    :center="center"
    :bounds="bounds"
    :options="{
      zoomControl: false,
      boxZoom: false,
      doubleClickZoom: false,
      scrollWheelZoom: false,
      touchZoom: false,
      dragging: false,
      preferCanvas: true,
    }"
    class="map-styling"
  >
    <l-tile-layer
      :url="$tileService.url"
      :attribution="$tileService.attribution"
    ></l-tile-layer>

    <l-rectangle
      v-for="geohashCell in geohashCells"
      :key="geohashCell.geohash"
      :bounds="[geohashCell.southWest, geohashCell.northEast]"
      :l-style="rectangleStyle"
      :interactive="false"
    ></l-rectangle>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LRectangle } from 'vue2-leaflet'

export default {
  components: {
    LMap,
    LTileLayer,
    LRectangle,
  },

  props: {
    bounds: undefined,

    geohashCells: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      zoom: 13,
      center: [45.34481892275193, 14.44775250818104],

      rectangleStyle: {
        fillColor: '#b7bf10',
        fillOpacity: 0.4,
        weight: 0,
      },
    }
  },
}
</script>
